import * as Yup from 'yup'

import { FormState } from './enums'
import { FormData } from './types'

export const validationSchema = Yup.object().shape({
  name: Yup.string()
    .required('This field is required'),
  email: Yup.string()
    .email('Invalid email')
    .required('This field is required'),
  message: Yup.string()
    .required('This field is required'),

})

type InitialValues = FormData & { pagePath: string }

export const initialValues = (path: string): InitialValues => ({
  name: '',
  email: '',
  phone: '',
  message: '',
  formName: 'Homepage Contact',
  pagePath: path,
})

export const loadingStateToSubmitMessage = (loadingState: FormState): string => {
  if (loadingState === FormState.Idle) {
    return 'GET IN TOUCH'
  } else if (loadingState === FormState.Submitting) {
    return 'SENDING MESSAGE'
  } else if (loadingState === FormState.Submitted) {
    return 'MESSAGE SENT'
  }
  return ''
}

export const stateToClassName = (errorState?: string, touchedState?: boolean): string => {
  return errorState && touchedState ? 'error' : ''
}

export const encode = (data: FormData): string => {
  const { formName, ...rest } = data
  const encoded = Object.keys(rest)
    .map((key: string) =>
      encodeURIComponent(key) + '=' + encodeURIComponent(data[key as keyof FormData]),
    )
  encoded.push(`form-name=${formName}`)
  return encoded.join('&')
}

export const submit = async (
  values: FormData,
  setErrors: (errors: FormData) => void,
  updateLoadingState: (arg: string) => void,
): Promise<void> => {
  try {
    updateLoadingState(FormState.Submitting)
    await fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode(values),
    })
    updateLoadingState(FormState.Submitted)
  } catch (error) {
    setErrors(error)
    updateLoadingState(FormState.Idle)
  }
}
