import React from 'react'
import { Field, FormikErrors, FormikTouched } from 'formik'

import { stateToClassName } from './utils'
import { FormData } from './types'

import styles from './contact.module.scss'

interface Props {
  name: keyof FormData
  type: string
  label: string
  errors: FormikErrors<FormData>
  touched: FormikTouched<FormData>
  placeholder?: string
  required?: boolean
}

export const FieldWrapper: React.FC<Props> = ({
  name,
  type,
  label,
  required = false,
  errors,
  touched,
  placeholder = '',
}) => (
  <div className={styles.fieldWrapper}>
    <label htmlFor={name} className={`${stateToClassName(errors[name], touched[name])}`}>
      <span>{label}{required && '*'}</span>
      {errors[name] && touched[name] && <span>&nbsp;&nbsp;&nbsp;&nbsp;{ errors[name] }</span>}
    </label>
    <Field
      className={`${styles.formField}`}
      name={name}
      placeholder={placeholder}
      id={name}
      type={type}
      component={type === 'textarea' && 'textarea'}
    />
  </div>
)
