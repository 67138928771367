import React from 'react'
import { Form, Field } from 'formik'

import { FieldWrapper } from './FieldWrapper'
import { FormState } from './enums'
import { FormProps } from './types'
import { loadingStateToSubmitMessage } from './utils'

export const ContactForm: React.FC<FormProps> = ({
  errors,
  touched,
  values,
  loadingState,
}) =>
  <Form
    className='cell large-7'
    name={values.formName}
    data-netlify='true'
    data-netlify-honeypot='bot-field'
  >
    <Field type='hidden' name='formName'/>
    <Field type='hidden' name='pagePath'/>
    <FieldWrapper
      name='name'
      type='text'
      label='Full name'
      errors={errors}
      touched={touched}
      required
    />
    <FieldWrapper
      name='email'
      type='email'
      label='Email'
      errors={errors}
      touched={touched}
      required
    />
    <FieldWrapper
      name='phone'
      type='text'
      label='Phone'
      errors={errors}
      touched={touched}
    />
    <FieldWrapper
      name='message'
      type='textarea'
      label='Your message'
      placeholder='Describe your project in a few sentences'
      errors={errors}
      touched={touched}
      required
    />
    <div>
      <input
        type='submit'
        className='btn btn--lg'
        value={loadingStateToSubmitMessage(loadingState)}
        disabled={loadingState !== FormState.Idle}
      />
    </div>
  </Form>
