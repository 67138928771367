import React, { useCallback, useRef, useState } from 'react'
import { useLocation } from '@reach/router'
import { Formik } from 'formik'
import { Element } from 'react-scroll'

import { ContactElement } from '../../enums'
import Section from '../../layout/Section'
import { Padding } from '../../layout/Section/utils'
import { CircledCheck } from '../icons'
import { ContactForm } from './ContactForm'
import { FormState } from './enums'
import { FormProps, FormOnSubmit } from './types'
import { initialValues, validationSchema, submit } from './utils'

import styles from './contact.module.scss'

interface Props {
  headline?: string
  bottomPadding?: Padding
  topPadding?: Padding
}

export const Contact: React.FC<Props> = ({
  headline = 'Tell us what you need',
  topPadding,
  bottomPadding,
}) => {
  const [loadingState, setLoadingState] = useState(FormState.Idle)
  const requestQuoteEl = useRef<HTMLDivElement>(null)

  const onSuccessButtonClick = useCallback(() => {
    setLoadingState(FormState.Idle)
  }, [])

  const onSubmit: FormOnSubmit = useCallback(async (values, { setErrors, resetForm }) => {
    await submit(values, setErrors, updateLoadingState)
    resetForm()
  }, [])

  // FIXME Revise this code
  const updateLoadingState = (loadingState: FormState) => {
    setLoadingState(loadingState)
    if (loadingState === FormState.Submitted && requestQuoteEl.current) {
      requestQuoteEl.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      })
    }
  }

  const { pathname } = useLocation()

  return (
    <Formik
      initialValues={initialValues(pathname)}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ errors, values, touched }) => (
        <>
          <Element name={ContactElement.ContactSection}></Element>
          <Section
            headline={loadingState === FormState.Submitted
              ? 'Thank you for contacting us!'
              : headline}
            topPadding={topPadding || 'large'}
            bottomPadding={bottomPadding || 'xlarge'}
            headlineDecoration='/images/illustrations/star-cluster2.svg'
            grid
            white
          >
            <div className={`${styles.contact} cell grid-x`} ref={requestQuoteEl}>
              <FormOrSuccessMessage
                errors={errors}
                touched={touched}
                values={values}
                loadingState={loadingState}
                onSuccessButtonClick={onSuccessButtonClick}
              />
            </div>
          </Section>
        </>
      )}
    </Formik>
  )
}

interface SuccessProps {
  onButtonClick: () => void
}
const Success: React.FC<SuccessProps> = ({ onButtonClick }) => (
  <div className={styles.success}>
    <CircledCheck className={styles.successIcon}/>
    <h4 className={styles.successTitle}>Your message was successfully submitted.</h4>
    <p className={styles.successSubtitle}>We&apos;ll get in touch with you as soon as possible.</p>
    <button onClick={onButtonClick} className={`${styles.successButton} btn green`}>
      Send new message
    </button>
  </div>
)

interface FormOrSuccessMessageProps extends FormProps {
  onSuccessButtonClick: () => void
}

const FormOrSuccessMessage: React.FC<FormOrSuccessMessageProps> = ({
  errors,
  touched,
  values,
  loadingState,
  onSuccessButtonClick,
}) => (
  loadingState === FormState.Submitted
    ? <Success onButtonClick={onSuccessButtonClick}/>
    : (
      <ContactForm
        errors={errors}
        touched={touched}
        values={values}
        loadingState={loadingState}
      />
    )
)
